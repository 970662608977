import * as React from "react";
import _ from "lodash";
// import PropTypes from 'prop-types'
import {
  DataGrid,
  GridToolbar,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { AppContext } from "../app/AppContext";
import { useSnackbar } from "notistack";
// eslint-disable-next-line
import { Button, Slide, Grow, Fade, Zoom, Collapse } from "@material-ui/core";
import MySnack1 from "./MySnack1";
import { types } from "../types/types";
import { interval } from "rxjs";

// aquí la falla de deformación de la tabla, es porque se recarga cada fetch este estilo
// solución hacerlo global

export default function DataRowK({ cols }) {
  const { state, dispatch, styleTable } = React.useContext(AppContext);
  const knoxClass = styleTable();

  React.useEffect(() => {
    state.reload === false &&
      dispatch({
        type: types.setAll,
        payload: {
          reload: true,
        },
      });
    return () => {};
    // eslint-disable-next-line
  }, [state.allTableData]);

  React.useEffect(() => {
    const allData = state.allTableData;
    const subscription = interval(1000).subscribe(() => {
      if (state.reload) {
        fetch(`${state.api_url}/api/knox/`)
          .then((res) => {
            return res.json();
          })
          .then((dat) => {
            const newArr = dat.data.map((item) => {
              return {
                id: item._id,
                chain_address: item.chain_address,
                e_mail: item.e_mail,
                status: item.status,
                operation: item.operation,
              };
            });

            const isEqual = _.isEqual(newArr, allData);

            if (!isEqual) {
              dispatch({
                type: types.setAll,
                payload: {
                  allTableData: newArr,
                  reload: false,
                },
              });
              subscription.unsubscribe();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, [state.reload]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <>
      <Button
        onClick={() => {
          alert(`I belong to snackbar with key ${key}`);
        }}
      >
        Alert
      </Button>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );
  const content = (title) => (key, message) =>
    <MySnack1 id={key} message={message} title={title} />;
  const hPlus = (title = "Titulo Nox", msg = "Mensaje Nox", varz = "default", time = 3000) => {
    // customized
    const props = {
      variant: varz,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Slide,
      autoHideDuration: time,
      preventDuplicate: true,
      persist: false,
      action,
      content: content(title),
    };
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, props);
  };

  const [pagination, setPagination] = React.useState({
    pagination: true, // true in free version
    autoPageSize: false,
    pageSize: 10, // max free version
  });

  // eslint-disable-next-line
  const onEditCell = ({ id, field, value }) => {
    const mdata = {
      method: "PUT",
      headers: {
        Accept: "*/*",
        Action: "sensible",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data'
      },
      body: JSON.stringify({
        [field]: value,
      }),
    };
    fetch(`${state.api_url}/api/knox/` + id, mdata)
      .then((res) => {
        return res.json();
      })
      .then((dat) => {
        dispatch({ type: types.setAll, payload: { reload: true } });
        hPlus(dat.title, dat.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // eslint-disable-next-line
  const onCellVisible = (e) => {
    cols.filter((dat) => dat.field === e.field && { visible: e.isVisible });
  };

  // eslint-disable-next-line
  const onChangePagination = (e) => {
    setPagination({ ...pagination, pageSize: e });
  };

  // eslint-disable-next-line
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    // <div className={cssDiv.root}>
    <DataGrid
      onCellEditCommit={onEditCell}
      // onColumnVisibilityChange={ onCellVisible }
      // onPageSizeChange={ onChangePagination }
      rowsPerPageOptions={[10, 20, 30, 50, 100]}
      className={knoxClass.root}
      columns={cols}
      rows={state.allTableData ? state.allTableData : []}
      components={{
        // Toolbar: CustomToolbar,
        Toolbar: GridToolbar,
      }}
      getRowClassName={(params) =>
        // console.log( params )
        `super-app-theme--${params.getValue(params.id, "status")}`
      }
      // loading={loading}
      // isRowSelectable={ (params) => params.row.status === 'Recibido' }
      // checkboxSelection
      disableSelectionOnClick
      // selectionModel
      {...pagination}
    />
    // </div>
  );
}
