// import 'antd/dist/antd.css'
import "bootstrap/dist/css/bootstrap.min.css";
import appReducer from "./app/appReducer";
import MainRouter from "./routers/MainRouter";
import { AppContext } from "./app/AppContext";
import React, { useEffect, useReducer } from "react";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider, darken, lighten } from "@material-ui/core/styles";
import { types } from "./types/types";

let drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  botBar: {
    top: "auto",
    bottom: 0,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 1, 3, 1),
  },
  drawer: {
    // overflow: 'hidden',
    width: drawerWidth,
    flexShrink: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drower: {
    // overflow: 'hidden',
    width: 0,
    flexShrink: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    overflow: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drowerPaper: {
    overflow: "hidden",
    width: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerContainer: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  nav_bar: {
    backgroundColor: theme.palette.getContrastText.name,
  },
}));

const cssPage = makeStyles((theme) => ({
  todo: {
    // display: 'flex',
    height: "100vh",
    // flexDirection: 'column',
  },
  router: {
    // display: 'flex',
    height: "100%",
    // flex: '1 1 auto',
    // flexDirection: 'column',
  },
  padre: {
    display: "flex",
    height: "100%",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  mainC: {
    display: "flex",
    // height: '100%',
    flex: "1 1 auto",
    overflowY: "auto",
    flexDirection: "row",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  mainF: {
    display: "flex",
    // height: '81vh',
    flex: "1 1 auto",
    overflowY: "auto",
    flexDirection: "column",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  contentC: {
    padding: "0",
    overflowY: "auto",
    display: "flex",
    flexDirection: "row",
  },
  contentF: {
    padding: "0",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  scroll: {
    paddingRight: ".4rem",
    overflowY: "auto",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      WebkitAppearance: "none",
      "&:vertical": {
        width: "8px",
      },
      "&:horizontal": {
        height: "10px",
      },
    },
    "&::-webkit-scrollbar-button": {
      "&:increment": {
        display: "none",
      },
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#797979",
      borderRadius: "20px",
      border: "1px solid #f1f2f3",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
  },
}));

function App() {
  const browserMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [state, dispatch] = useReducer(appReducer, {}, () => {
    return {
      app: {
        name: "Luunox",
      },
      user: {
        name: "knox",
        pass: "315456",
      },
      browsermode: browserMode ? "dark" : "light",
      darkmode: browserMode,
      active: "/",
      reload: true,
      drawer: false,
      añadir: false,
      showRegForm: false,
      api_url: "https://knoxapi.ml",
      modalFormData: { id: 0 },
      allTableData: [],
    };
  });

  useEffect(() => {
    dispatch({
      type: types.setAll,
      payload: {
        darkmode: browserMode,
        browsermode: browserMode ? "dark" : "light",
      },
    });

    return () => {};
  }, [browserMode]);

  const textcolor = state.darkmode ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)";
  const primary = state.darkmode ? "rgba(100, 180, 160, 1)" : "rgba(20, 40, 60, 1)";
  const secondary = state.darkmode ? "rgba(0, 68, 100, 1)" : "rgba(10, 150, 100, 1)";
  const background = state.darkmode ? "rgba(50, 50, 50, 0.7)" : "rgba(200, 200, 200, 0.7)";
  const backgpaper = state.darkmode ? "rgba(50, 50, 50, 0.94)" : "rgba(200, 200, 200, 0.86)";

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            // '-apple-system',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            // 'Roboto',
            // '"Helvetica Neue"',
            // 'Arial',
            // 'sans-serif',
            // '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            // '"Segoe UI Symbol"',
          ].join(","),
          fontSize: 13,
          //  color: textcolor,
          allVariants: {
            color: textcolor,
          },
        },
        palette: {
          type: state.darkmode ? "dark" : "light",
          primary: {
            // light: will be calculated from palette.primary.main,
            main: primary,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
          },
          secondary: {
            // light: '#0066ff',
            main: secondary,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: state.darkmode ? 'rgba(10, 150, 100, 1)' : 'rgba(0, 68, 100, 1)',
          },
          text: {
            primary: textcolor,
            // secondary: state.darkmode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
          },
          background: {
            default: background,
            paper: backgpaper,
          },
          // Used by `getContrastText()` to maximize the contrast between
          // the background and the text.
          contrastThreshold: 3,
          // Used by the functions below to shift a color's luminance by approximately
          // two indexes within its tonal palette.
          // E.g., shift from Red 500 to Red 300 or Red 700.
          tonalOffset: 0.2,
        },
      }),
    // eslint-disable-next-line
    [state.darkmode]
  );

  const styleTable = makeStyles((theme) => {
    const getBackgroundColor = (color) =>
      state.darkmode ? darken(color, 0.1) : lighten(color, 0.1);
    const getHoverBackgroundColor = (color) =>
      state.darkmode ? darken(color, 0.3) : lighten(color, 0.3);

    return {
      root: {
        backgroundColor: !state.darkmode ? "rgba(30,140,100,.7)" : "rgba(10,20,80,.7)",
        border: `1px solid ${!state.darkmode ? "#303030" : "#f0f0f0"}`,
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeader": {
          borderRight: `1px solid ${!state.darkmode ? "#303030" : "#f0f0f0"}`,
        },
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: !state.darkmode ? "rgba(10,120,80,.6)" : "rgba(10,60,100,.6)",
          border: `1px solid ${!state.darkmode ? "#303030" : "#f0f0f0"}`,
          borderLeft: `none`,
          borderRight: `none`,
        },
        "& .MuiDataGrid-cell": {
          // backgroundColor: !state.darkmode ? 'rgba(30,140,100,.7)' : 'rgba(10,20,80,.7)',
          border: `1px solid ${!state.darkmode ? "#303030" : "#f0f0f0"}`,
          borderLeft: `none`,
          borderTop: `none`,
        },
        "& .MuiPaginationItem-root": {
          borderRadius: 0,
        },
        "& .super-app-theme--Recibido": {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        "& .super-app-theme--Pagado": {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
          },
        },
        "& .super-app-theme--Rechazado": {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
          },
        },
        "& .super-app-theme--Error": {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
        "& .super-app-theme--Actualizado": {
          backgroundColor: getBackgroundColor("rgb(200,30,200)"),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor("rgb(180,30,200)"),
          },
        },
      },
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ state, dispatch, cssPage, useStyles, styleTable }}>
        <CssBaseline />
        <MainRouter />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
