import React, { useContext } from "react";
import { AppContext } from "../app/AppContext";
import { Divider, Drawer } from "@material-ui/core";
import { types } from "../types/types";
import ItemKe from "./ItemKe";

const Imgs = require.context("../assets", true);

export const DrawerBar = () => {
  const { state, dispatch } = useContext(AppContext);
  const { useStyles } = useContext(AppContext);
  const css = useStyles();
  const open = state.drawer;
  let logo_main;

  const handleDrawer = () => {
    dispatch({
      type: types.setAll,
      payload: { drawer: !state.drawer },
    });
  };

  state.darkmode
    ? (logo_main = Imgs("./logos/main_logo.png").default)
    : (logo_main = Imgs("./logos/main_logo.png").default);

  return (
    <Drawer
      className={open ? css.drawer : css.drower}
      variant="permanent"
      anchor="left"
      open={open}
      classes={{
        paper: open ? css.drawerPaper : css.drowerPaper,
      }}
      onMouseLeave={handleDrawer}
    >
      <div className={css.drawerHeader}>
        <div
          style={{
            backgroundImage: `url(${logo_main})`,
            backgroundSize: "100% 100%",
            height: 160 + "px",
            width: 160 + "px",
            // border: '1px solid red',
          }}
        />
        Luunox
      </div>

      <Divider />

      <div className={css.drawerContainer}>
        <ItemKe
          path="/"
          uno="Inicio"
          dos="Luunox"
          svg="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"
        />

        <ItemKe
          path="/amazon"
          uno="Amazon"
          dos="Prime"
          svg="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 
                1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 
                0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 
                .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 
                1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 12 7.4l3.38 4.6L17 10.83 14.92 8H20v6z"
        />

        <Divider />

        <ItemKe
          path="/about"
          uno="Acerca de"
          dos="Luunox"
          svg="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 
                2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        />
      </div>
    </Drawer>
  );
};
