import React, { useContext, useState } from "react";
// import { useTheme } from '@material-ui/core'
import { AppContext } from "../app/AppContext";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
// eslint-disable-next-line
import { Slide, Grow, Fade, Zoom, Collapse, Button } from "@material-ui/core";
// import CustomSnack from './CustomSnack.tsx'
import CustomSnack from "./CustomSnack.js";
import { types } from "../types/types";

export const FooBar = () => {
  // eslint-disable-next-line
  const [snakKey, setSnakKey] = useState();
  const { state, dispatch, useStyles } = useContext(AppContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const theme = useTheme()
  const css = useStyles();

  const hClose = () => {};

  const action = (key) => (
    <>
      <Button
        onClick={() => {
          alert(`I belong to snackbar with key ${key}`);
        }}
      >
        Alert
      </Button>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  const content = (title) => (key, message) =>
    <CustomSnack id={key} message={message} title={title} />;
  // eslint-disable-next-line
  const hPlus =
    (title = "Titulo Nox", msg = "Mensaje Nox", varz = "default", time = 3000) =>
    () => {
      // customized
      const props = {
        variant: varz,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: Slide,
        autoHideDuration: time,
        preventDuplicate: true,
        persist: false,
        action,
        content: content(title),
      };
      // variant could be success, error, warning, info, or default
      setSnakKey(enqueueSnackbar(msg, props));
    };

  const añadir = async () => {
    try {
      let rx, dat, USD_PEN;
      rx = await fetch(`${state.api_url}/USD_PEN`);
      dat = await rx.json();
      USD_PEN = dat;

      dispatch({
        type: types.setAll,
        payload: {
          añadir: true,
          modalFormData: {},
          showRegForm: true,
          USD_PEN,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="foobar">
      <AppBar position="fixed" color="secondary" className={css.botBar}>
        <Toolbar>
          <IconButton hidden edge="start" color="inherit" aria-label="open drawer">
            <MenuIcon />
          </IconButton>
          <Fab color="default" aria-label="add" className={css.fabButton} onClick={añadir}>
            <AddIcon />
          </Fab>
          <div className={css.grow} />
          <IconButton hidden color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton hidden edge="end" color="inherit" onClick={hClose}>
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};
