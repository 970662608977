import './Main.css'
import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { HomePage } from '../pages/HomePage'
import { SnackbarProvider } from 'notistack'
import { FooBar } from '../components/FooBar'
import { AboutPage } from '../pages/AboutPage'
import { AppContext } from '../app/AppContext'
import { AmazonPage } from '../pages/AmazonPage'
import { Redirect, Route, Switch } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { types } from '../types/types'

const MainRouter = () => {
  const { cssPage, dispatch } = useContext( AppContext )
  const css = cssPage()

  const handleDrawer = () => {
    dispatch({
      type: types.setAll,
      payload: { drawer: false }
    })
  }
  
  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
      <Navbar />
        <div className={ css.router } onClick={ handleDrawer }>
          <Switch>
            <Route exact path="/" component={ HomePage } />
            <Route exact path="/about" component={ AboutPage } />
            <Route exact path="/amazon" component={ AmazonPage } />

            <Redirect to="/" />
          </Switch>
        </div>

        <FooBar />
      </SnackbarProvider>
    </Router>
  )
}

export default MainRouter
