import React, { useContext } from "react";
import { AppContext } from "../app/AppContext";
import AppBar from "@material-ui/core/AppBar";
import Switch from "@material-ui/core/Switch";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DarkIcon from "@material-ui/icons/Brightness2";
import LightIcon from "@material-ui/icons/Brightness7";
import { IconButton, useTheme } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { BrowserView, MobileView } from "react-device-detect";
import { types } from "../types/types";

export const AppTopBar = () => {
  const { state, dispatch, useStyles } = useContext(AppContext);
  const open = state.drawer;
  const theme = useTheme();
  const css = useStyles();

  const handleDrawer = () => {
    dispatch({
      type: types.setAll,
      payload: { drawer: !state.drawer },
    });
  };

  const handleDark = () => {
    state.browsermode === "light"
      ? dispatch({
          type: types.setAll,
          payload: { darkmode: !state.darkmode },
        })
      : window.innerWidth < 576
      ? alert(
          "Usuarios del modo oscuro en su sistema no pueden cambiar al modo claro en la web por temas de visibilidad"
        )
      : dispatch({
          type: types.setAll,
          payload: { darkmode: !state.darkmode },
        });
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: "rgba(50, 50, 50, 1)",
      "&$checked": {
        color: "rgba(50, 200, 240, 1)",
      },
      "&$checked + $track": {
        backgroundColor: "rgba(255, 255, 255, 1)",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <AppBar position="fixed" color="secondary" className={!open ? css.appBar : css.appBarShift}>
      <Toolbar>
        <IconButton
          hidden
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          className={css.menuButton}
        >
          {!open ? (
            <MenuIcon />
          ) : theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>

        <Typography variant="h6">
          <MobileView>{state.app.name} - Mobile</MobileView>

          <BrowserView>{state.app.name} - Desktop</BrowserView>
        </Typography>

        <div className={css.grow} />

        <LightIcon />
        <CustomSwitch
          checked={state.darkmode}
          onChange={handleDark}
          name="checked"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <DarkIcon />
      </Toolbar>
    </AppBar>
  );
};
