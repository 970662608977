import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  List,
  useTheme,
} from "@material-ui/core";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../app/AppContext";
import { types } from "../types/types";

const ItemKe = ({ path, uno, dos, svg }) => {
  // dispatch({ type: types.setAll, payload: { active: path } })
  const { state, dispatch } = useContext(AppContext);
  const theme = useTheme();

  return (
    <List
      onClick={() =>
        dispatch({ type: types.setAll, payload: { active: path } })
      }
    >
      <NavLink to={`${path}`}>
        <ListItem
          button
          style={{
            color: theme.palette.text.primary,
            backgroundColor:
              state.active === path
                ? "rgba(255, 255, 255, 0.2)"
                : "rgba(255, 255, 255, 0)",
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <path d={svg} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={uno} secondary={dos} />
        </ListItem>
      </NavLink>
    </List>
  );
};

export default ItemKe;
