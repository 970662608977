import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { Typography, useTheme } from '@material-ui/core'

export const MainHeader = ( { title } ) => {
  const theme = useTheme()

  return (
    <div
      style={{
      width: '100%',
      padding: '0 15px 0 15px',
      backgroundColor: theme.palette.background.default
      }}
    >
      <Box>
        <br />
          <Typography variant="h4">
            { title }
          </Typography>
        <br />
      </Box>
    </div>
  )
}

MainHeader.propTypes = {
  title: PropTypes.string.isRequired,
}
