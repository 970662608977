
const appReducer = ( state = {}, { type, payload } ) => {
  switch ( type ) {
    case '[SetAll]Data':
      return { ...state, ...payload }

    default:
      return state
  }
}

export default appReducer
