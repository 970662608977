import React, { useContext, useState } from "react";
import "moment/locale/es-us";
import moment, { now } from "moment";
import { Form, Input, Select, message } from "antd";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
import { useSnackbar } from "notistack";
// eslint-disable-next-line
import { Button, Slide, Grow, Fade, Zoom, Collapse } from "@material-ui/core";
import MySnack1 from "./MySnack1";
import { AppContext } from "../app/AppContext";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

const defaultTheme = createTheme();
const modalStyle = makeStyles(
  (theme) => ({
    field1: {
      width: "calc(100%)",
      [theme.breakpoints.up("sm")]: {
        paddingRight: "6px",
        width: "calc(50%)",
      },
    },
    field2: {
      width: "calc(100%)",
      [theme.breakpoints.up("sm")]: {
        // paddingRight: '6px',
        width: "calc(50%)",
      },
    },
    fieldDolar: {
      width: "calc(100%)",
      [theme.breakpoints.up("md")]: {
        // paddingRight: '6px',
        width: "calc(50%)",
      },
    },
    imgSelect: {
      height: "500px",
      width: "calc(100%)",
      [theme.breakpoints.up("sm")]: {
        width: "calc(50%)",
      },
    },
  }),
  { defaultTheme }
);

const MyForm = () => {
  // eslint-disable-next-line
  const { state, dispatch } = useContext(AppContext);
  const modal = state.modalFormData;
  // eslint-disable-next-line
  const [image64, setImage64] = useState();
  const [fileImg, setFileImg] = useState();
  // eslint-disable-next-line
  const [imageUrl, setImageUrl] = useState(modal?.imagen && modal.imagen);
  const [uploading, setUploading] = useState();
  // eslint-disable-next-line
  const [dolarValue, setDolarValue] = useState(
    state.USD_PEN?.venta ? state.USD_PEN.venta.toFixed(2) : 0
  );
  const datePay = modal?.pay_date ? new Date(modal?.pay_date) : !state.añadir && new Date(now());
  const dateUpload = modal?.upload_date ? new Date(modal?.upload_date) : new Date(now());
  const cssModal = modalStyle();

  const [form] = Form.useForm();

  const changeFinance = (e) => {
    // console.log(e);

    const fiats = form.getFieldValue("fiats");
    const fiatCoin = form.getFieldValue("fiat_coin");
    const firuPrice = form.getFieldValue("firu_price");
    const penPrice = form.getFieldValue("pen_price");

    if (fiatCoin === "S/") {
      const PEN_VALUE = fiats;
      const FIRU_PRICE_IN_USD = firuPrice;
      const PEN_VALUE_TO_USD = PEN_VALUE / penPrice;
      const FIRUS_USD_FROM_PEN_VALUE = PEN_VALUE_TO_USD / FIRU_PRICE_IN_USD;

      form.setFieldsValue({
        firus: FIRUS_USD_FROM_PEN_VALUE.toFixed(8),
      });
    } else if (fiatCoin === "$.") {
      const USD_VALUE = fiats;
      const FIRU_PRICE_IN_USD = firuPrice;
      const FIRUS_USD = USD_VALUE / FIRU_PRICE_IN_USD;

      form.setFieldsValue({
        firus: FIRUS_USD.toFixed(8),
      });
    }
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <>
      <Button
        onClick={() => {
          alert(`I belong to snackbar with key ${key}`);
        }}
      >
        Alert
      </Button>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );
  const content = (title) => (key, message) =>
    <MySnack1 id={key} message={message} title={title} />;
  const hPlus = (title = "Titulo Nox", msg = "Mensaje Nox", varz = "default", time = 3000) => {
    // customized
    const props = {
      variant: varz,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Slide,
      autoHideDuration: time,
      preventDuplicate: true,
      persist: false,
      action,
      content: content(title),
    };
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, props);
  };

  function getBaseUrl(file) {
    const filo = URL.createObjectURL(file);
    return filo;
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  // eslint-disable-next-line
  function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const btnSubmit = (e) => {
    // e.preventDefault()
    // console.log(e);
    const dateSubir = moment(e.date_upl + "_" + e.hour_upl, "yyyy-MM-DD_HH:mm");
    const datePago = moment(e.date_pay + "_" + e.hour_pay, "yyyy-MM-DD_HH:mm");
    const formData = new FormData();

    // formData.append( e )
    e.e_mail && formData.append("e_mail", e.e_mail);
    e.operation && formData.append("operation", e.operation);
    e.chain_address && formData.append("chain_address", e.chain_address);
    dateSubir.isValid() && formData.append("upload_date", dateSubir);
    e.cta_dest && formData.append("cta_dest", e.cta_dest);
    e.firus && formData.append("firus", e.firus);
    e.fiats && formData.append("fiats", e.fiats);
    e.fiat_coin && formData.append("fiat_coin", e.fiat_coin);
    e.firu_price && formData.append("firu_price", e.firu_price);
    e.pen_price && formData.append("pen_price", e.pen_price);
    e.hash_tx && formData.append("hash_tx", e.hash_tx);
    datePago.isValid() && formData.append("pay_date", datePago);

    fileImg && formData.append("voucher", fileImg, fileImg.name);

    const mdata = {
      method: state.añadir ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        // Action: "create_voucher",
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
      },
    };

    state.añadir
      ? axios
          .post(`${state.api_url}/api/knox/`, formData, mdata)
          .then((res) => {
            // console.log(res.data.message);
            return res.data;
          })
          .then((dat) => {
            // dispatch({ type: types.setAll, payload: { showRegForm: false, reload: true }})
            hPlus(dat.title, dat.message);
          })
          .catch((err) => {
            console.log(err);
          })
      : axios
          .put(`${state.api_url}/api/knox/` + modal?._id, formData, mdata)
          .then((res) => {
            return res.data;
          })
          .then((dat) => {
            // dispatch({ type: types.setAll, payload: { showRegForm: false, reload: true }})
            hPlus(dat.title, dat.message);
          })
          .catch((err) => {
            console.log(err);
          });
  };

  const onImgChange = ({ target }) => {
    if (target.value === "") {
      setImageUrl();
      return 0;
    }
    const file = target.files[0];
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return isJpgOrPng;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return isLt2M;
    }
    const urlImg = getBaseUrl(file);
    getBase64(file, (result) => {
      setImage64(result);
    });
    setFileImg(file);
    setImageUrl(urlImg);
    setUploading(false);
  };

  const getImgFrom = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.target.files[0];
  };

  const uploadButton = (
    <div
      className="position-relative d-inline-block start-50 top-50 translate-middle"
      style={{
        padding: "5px 10px",
        borderStyle: "dotted",
        borderWidth: "1px",
        borderColor: "GrayText",
      }}
    >
      {uploading ? (
        <LoadingOutlined className="position-relative start-50 translate-middle-x" />
      ) : (
        <PlusOutlined className="position-relative start-50 translate-middle-x" />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        form={form}
        initialValues={{
          id: modal?._id,
          e_mail: modal?.e_mail,
          operation: modal?.operation,
          chain_address: modal?.chain_address,
          date_upl: moment(dateUpload, "yyyy-MM-DD").isValid()
            ? moment(dateUpload).format("yyyy-MM-DD")
            : null,
          hour_upl: moment(dateUpload, "HH:mm").isValid()
            ? moment(dateUpload).format("HH:mm")
            : null,
          cta_dest: modal?.cta_dest,
          firus: modal?.firus ? modal.firus : 0,
          fiats: modal?.fiats ? modal.fiats : 0,
          fiat_coin: modal?.fiat_coin ? modal?.fiat_coin : "S/",
          firu_price: modal?.firu_price ? modal.firu_price : 1,
          pen_price: modal?.pen_price
            ? modal.pen_price
            : modal?.firu_price
            ? (modal.firu_price * dolarValue).toFixed(2)
            : dolarValue,
          hash_tx: modal?.hash_tx,
          date_pay: moment(datePay, "yyyy-MM-DD").isValid()
            ? moment(datePay).format("yyyy-MM-DD")
            : null,
          hour_pay: moment(datePay, "HH:mm").isValid() ? moment(datePay).format("HH:mm") : null,
        }}
        name="complex-form"
        onFinish={btnSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="id"
            className={cssModal.field1 + " m-0"}
            rules={[{ required: false }]}
            style={{ display: "inline-block" }}
          >
            <Input addonBefore="ID" disabled />
          </Form.Item>

          <Form.Item
            name="e_mail"
            className={cssModal.field2 + " m-0"}
            rules={[{ required: state.añadir, message: "Email es requerido" }]}
            style={{ display: "inline-block" }}
          >
            <Input addonBefore="E-mail" disabled={modal.e_mail !== undefined} />
          </Form.Item>
        </Form.Item>
        <p />
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="operation"
            className={cssModal.field1 + " m-0"}
            rules={[{ required: false }]}
            style={{ display: "inline-block" }}
          >
            <Input addonBefore="OP" disabled={modal.operation !== undefined} />
          </Form.Item>

          <Form.Item
            name="chain_address"
            className={cssModal.field2 + " m-0"}
            rules={[{ required: state.añadir, message: "Adress es requerido" }]}
            style={{ display: "inline-block" }}
          >
            <Input addonBefore="Address" disabled={modal.chain_address !== undefined} />
          </Form.Item>
        </Form.Item>
        <p />
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="date_upl"
            className={cssModal.field1 + " m-0"}
            rules={[{ required: false }]}
            style={{ display: "inline-block" }}
          >
            <Input
              addonBefore="Fecha de ingreso"
              type="date"
              disabled={modal.upload_date !== undefined}
            />
          </Form.Item>

          <Form.Item
            name="hour_upl"
            className={cssModal.field2 + " m-0"}
            rules={[{ required: false }]}
            style={{ display: "inline-block" }}
          >
            <Input
              addonBefore="Hora de ingreso"
              type="time"
              disabled={modal.upload_date !== undefined}
            />
          </Form.Item>
        </Form.Item>
        <br />

        <div className="row m-0">
          <Form.Item label="" className="col-24 p-0 pe-3 w-50">
            <Form.Item
              noStyle
              name="imagen"
              valuePropName="files"
              getValueFromEvent={getImgFrom}
              rules={[{ required: state.añadir, message: "Imagen es requerida" }]}
              style={{ height: "100%", width: "100%" }}
            >
              <div className="col p-0 w-100" style={{ height: 262, width: "100%" }}>
                <label
                  htmlFor="contained-file"
                  style={{
                    cursor: !modal?.imagen ? "pointer" : "no-drop",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Input
                    hidden
                    type="file"
                    accept="image/*"
                    id="contained-file"
                    onChange={onImgChange}
                    disabled={modal.imagen !== undefined}
                  />
                  {!imageUrl ? (
                    uploadButton
                  ) : (
                    <img
                      src={`${imageUrl}`}
                      alt="avatar"
                      style={{ height: "100%", width: "100%" }}
                    />
                  )}
                </label>
              </div>
            </Form.Item>
          </Form.Item>

          <div className="col p-0" style={{ width: "50%" }}>
            <Form.Item
              className="m-0"
              name="cta_dest"
              rules={[{ required: !state.añadir, message: "Cuenta de destino es requerida" }]}
            >
              <Input addonBefore="Cta. destino" disabled={modal.cta_dest !== undefined} />
            </Form.Item>
            <p />
            <Form.Item
              className="m-0"
              name="firus"
              rules={[{ required: !state.añadir, message: "Monto Firu's es requerido" }]}
            >
              <Input
                min={0}
                step={0.00000001}
                type="number"
                addonBefore="Monto Firu's"
                disabled={modal.firus !== undefined}
                style={{ textAlign: "center" }}
              />
            </Form.Item>
            <p />
            <Form.Item
              className="m-0"
              name="fiats"
              rules={[{ required: !state.añadir, message: "Monto Fiat es requerido" }]}
            >
              <Input
                min={0}
                step={0.01}
                type="number"
                onChange={changeFinance}
                addonBefore="Monto Fiat"
                addonAfter={
                  <Form.Item noStyle name="fiat_coin">
                    <Select
                      className="select-after"
                      onChange={changeFinance}
                      disabled={modal.fiat_coin !== undefined}
                    >
                      <Option value="S/">S/</Option>
                      <Option value="$.">$.</Option>
                    </Select>
                  </Form.Item>
                }
                disabled={modal.fiats !== undefined}
                style={{ textAlign: "center" }}
              />
            </Form.Item>
            <p />
            <Form.Item className="m-0" style={{ marginBottom: 0 }}>
              <Form.Item
                className={cssModal.fieldDolar + " m-0"}
                name="firu_price"
                style={{ display: "inline-block" }}
                rules={[{ required: !state.añadir, message: "Precio Firu es requerido" }]}
              >
                <Input
                  min={0}
                  step={0.00000001}
                  type="number"
                  onChange={changeFinance}
                  addonBefore="Firu"
                  prefix="Precio:"
                  suffix="$"
                  style={{ textAlign: "center" }}
                  disabled={modal.firu_price !== undefined}
                />
              </Form.Item>
              <Form.Item
                className={cssModal.fieldDolar + " m-0"}
                name="pen_price"
                style={{ display: "inline-block" }}
                rules={[{ required: !state.añadir, message: "Precio de cambio es requerido" }]}
              >
                <Input
                  min={0}
                  step={0.01}
                  type="number"
                  onChange={changeFinance}
                  addonBefore="Dolar"
                  prefix="Valor:"
                  suffix="S/"
                  style={{ textAlign: "center" }}
                  disabled={modal.pen_price !== undefined}
                />
              </Form.Item>
            </Form.Item>
            <p />
            <Form.Item
              className="m-0"
              name="hash_tx"
              rules={[{ required: !state.añadir, message: "Hash_TX es requerido" }]}
            >
              <Input addonBefore="Hash TX." disabled={modal.hash_tx !== undefined} />
            </Form.Item>
            <p />

            <Form.Item
              name="date_pay"
              className="m-0"
              rules={[{ required: !state.añadir, message: "Fecha de pago es requerida" }]}
              style={{ display: "inline-block", width: "calc(100%)" }}
            >
              <Input
                prefix={<span>Fecha de pago: &nbsp; </span>}
                type="date"
                disabled={modal.pay_date !== undefined}
              />
            </Form.Item>
            <p />

            <Form.Item
              name="hour_pay"
              className="m-0"
              rules={[{ required: !state.añadir, message: "Hora de pago es requerida" }]}
              style={{ display: "inline-block", width: "calc(100%)" }}
            >
              <Input
                prefix={<span>Hora de pago: &nbsp; </span>}
                type="time"
                disabled={modal.pay_date !== undefined}
              />
            </Form.Item>
          </div>
        </div>
        <br />
        <button
          className="btn btn-primary w-100"
          type="submit"
          disabled={
            modal?.fiats !== undefined &&
            modal?._id !== undefined &&
            modal?.e_mail !== undefined &&
            modal?.operation !== undefined &&
            modal?.chain_address !== undefined &&
            modal?.upload_date !== undefined &&
            modal?.imagen !== undefined &&
            modal?.cta_dest !== undefined &&
            modal?.firus !== undefined &&
            modal?.fiats !== undefined &&
            modal?.fiat_coin !== undefined &&
            modal?.hash_tx !== undefined &&
            modal?.pay_date !== undefined &&
            modal?.firu_price !== undefined &&
            modal?.pen_price !== undefined
          }
        >
          Guardar
        </button>
      </Form>
    </>
  );
};

export default MyForm;
