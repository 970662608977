import React, { useContext, useEffect } from 'react'
import { Box, Container, Toolbar } from '@material-ui/core'
import { AppContext } from '../app/AppContext'
import { MainHeader } from '../components/MainHeader'
import Typography from '@material-ui/core/Typography'
import { types } from '../types/types'

const Imgs = require.context( '../assets', true )

export const AmazonPage = () => {
  const { dispatch, cssPage } = useContext( AppContext )
  const css = cssPage()
  const fondo = Imgs('./fondos/rAmazon.jpg').default

  useEffect(
    () => {
      dispatch({
        type: types.setAll,
        payload: { app: { name: 'Inicio' } }
      })
      return () => {
        
      }
    },
  // eslint-disable-next-line
  []
  )

  return (
    <div
      className={ css.padre }
      style={{ backgroundImage: `url(${ fondo })`, backgroundSize: '100% 100%' }}
    >
      <Toolbar id="top-amazon" />
      <MainHeader title="Amazon" />
      <main className={ css.mainF }>
        <Container className={ css.contentF + ' ' + css.scroll }>
          <Box>
            <Typography variant="h6">Contenido de amazon</Typography>
          </Box>
        </Container>
      </main>
      <Toolbar id="bot-amazon" />
    </div>
  )
}
