import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../app/AppContext";
import { Modal } from "antd";
// import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import Toolbar from "@material-ui/core/Toolbar";
import DataRowK from "../components/DataRowsK";
import Container from "@material-ui/core/Container";
import { MainHeader } from "../components/MainHeader";
// import Typography from "@material-ui/core/Typography";
import MyForm from "../components/MyForm";
import { useSnackbar } from "notistack";
// eslint-disable-next-line
import { Slide, Grow, Fade, Zoom, Collapse } from "@material-ui/core";
import MySnack1 from "../components/MySnack1";
import { types } from "../types/types";

const Imgs = require.context("../assets", true);

export const HomePage = () => {
  // eslint-disable-next-line
  const [breakPoint, setBreakPoint] = useState(
    window.innerWidth >= 1400
      ? { xxl: true }
      : window.innerWidth >= 1200
      ? { xl: true }
      : window.innerWidth >= 992
      ? { lg: true }
      : window.innerWidth >= 768
      ? { md: true }
      : window.innerWidth >= 576
      ? { sm: true }
      : window.innerWidth < 576 && { xs: true }
  );
  const [cols, setCols] = useState([]);
  const { state, dispatch, cssPage } = useContext(AppContext);
  const fondo = Imgs("./fondos/main_fondo.png").default;
  const css = cssPage();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <>
      <Button
        onClick={() => {
          alert(`I belong to snackbar with key ${key}`);
        }}
      >
        Alert
      </Button>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );
  const content = (title) => (key, message) =>
    <MySnack1 id={key} message={message} title={title} />;
  // eslint-disable-next-line
  const hPlus = (title = "Titulo Nox", msg = "Mensaje Nox", varz = "default", time = 3000) => {
    // customized
    const props = {
      variant: varz,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Slide,
      autoHideDuration: time,
      preventDuplicate: true,
      persist: false,
      action,
      content: content(title),
    };
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, props);
  };

  const abrirForm = (params) => async () => {
    try {
      let rx, dat, USD_PEN;
      rx = await fetch(`${state.api_url}/USD_PEN`);
      dat = await rx.json();
      USD_PEN = dat;

      rx = await fetch(`${state.api_url}/api/knox/` + params.row.id);
      dat = await rx.json();
      dispatch({
        type: types.setAll,
        payload: {
          añadir: false,
          modalFormData: dat.data,
          reload: false,
          showRegForm: true,
          USD_PEN,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onCansel = () => {
    dispatch({
      type: types.setAll,
      payload: { showRegForm: false, reload: true },
    });
  };

  useEffect(() => {
    setCols([
      {
        field: "id",
        headerName: "ID",
        description: "Identificador",
        width: 200,
        editable: false,
        filterable: false,
        hidden: true,
      },
      {
        field: "chain_address",
        headerName: "Address",
        description: "Dirección de billetera",
        width: 260,
        editable: true,
      },
      {
        field: "e_mail",
        headerName: "Email",
        description: "Correo del cliente",
        width: 240,
        editable: true,
      },
      {
        field: "operation",
        headerName: "Operación",
        description: "Número de operación",
        width: 158,
        editable: true,
      },
      {
        field: "status",
        headerName: "Estado",
        description: "Estado de operación",
        width: 158,
        editable: true,
      },
      {
        field: "fo",
        headerName: "Formulario",
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 40 }}
              onClick={abrirForm(params)}
            >
              Abrir
            </Button>
          </strong>
        ),
        width: 162,
        filterable: false,
      },
    ]);

    const width = window.innerWidth;
    if (width >= 1400) {
      setBreakPoint({ xxl: true });
    } else if (width >= 1200) {
      setBreakPoint({ xl: true });
    } else if (width >= 992) {
      setBreakPoint({ lg: true });
    } else if (width >= 768) {
      setBreakPoint({ md: true });
    } else if (width >= 636) {
      setBreakPoint({ sm: true });
    } else if (width < 636) {
      setBreakPoint({ xs: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={css.padre}
      style={{ backgroundImage: `url(${fondo})`, backgroundSize: "100% 100%" }}
    >
      <Toolbar id="top-main" />
      <MainHeader title="Firux Admin" />
      <main className={css.mainC}>
        <Container className={css.contentC + " " + css.scroll}>
          <DataRowK cols={cols} />
          <Modal
            visible={state.showRegForm}
            onCancel={onCansel}
            footer={null}
            title="Formulario de registro"
            width={
              breakPoint.xs
                ? 540
                : breakPoint.sm
                ? 620
                : breakPoint.md
                ? 620
                : breakPoint.lg
                ? 700
                : breakPoint.xl
                ? 800
                : 900
            }
            destroyOnClose
          >
            <MyForm />
          </Modal>
        </Container>
      </main>
      <Toolbar id="bot-main" />
    </div>
  );
};
