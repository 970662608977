import 'antd/dist/antd.css'
import React from 'react'
import { DrawerBar } from './DrawerBar'
import { AppTopBar } from './AppTopBar'

export default function Navbar() {

  return (
    <div id="navbar">
      <AppTopBar />
      <DrawerBar />
    </div>
  )
}
